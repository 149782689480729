/* stylelint-disable */
import { Progress, Space } from "@reifyhealth/picasso-pkg";
import * as budgetMatrix from "@model/budgets/matrix";
import { GreenMarginTrendUpwardArrow } from "@app/components/common/icons/GreenMarginTrendUpwardArrow";
import { RedMarginTrendDownwardArrow } from "@app/components/common/icons/RedMarginTrendDownwardArrow";
import { displayText, money, Money, toNumber } from "@lib/currency";
import { styled } from "@linaria/react";
import {
  useBudgetMatrix,
  useBudget,
  BudgetMatrixProvider,
} from "@model/budgets/matrix/hooks";
import { SaveIndication } from "@components/common/SaveIndication";
import { useParams } from "react-router-dom";

const Container = styled.section`
  border: 1px solid var(--component-border);
  padding: 8px;
  margin-bottom: 26px;

  .title {
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-medium);
    color: var(--component-primary-text);
  }

  .entry {
    font-size: var(--font-size-footnote);
    color: var(--component-tertiary-text);
  }

  .value {
    font-size: var(--font-size-footnote);
    float: right;
  }

  .negative {
    color: var(--shared-danger);
  }

  .positive {
    color: var(--shared-success);
  }
`;

interface BudgetVersionCreationTotalsProps {
  title: string;
  cost: Money | null;
  charge: Money | null;
  margin: Money | null;
  calculatingTotals: boolean;
}

const isNumberNegative = (num: number) => num < 0;
const isNumberPositive = (num: number) => num > 0;

const BudgetVersionCreationTotal = ({
  title,
  cost,
  charge,
  margin,
  calculatingTotals,
}: BudgetVersionCreationTotalsProps) => {
  const titleAsTestId = title.toLowerCase().trim().replaceAll(" ", "-");

  const renderMargin = () => {
    if (calculatingTotals || !margin || !cost || !charge) {
      return (
        <tr data-testid={`${titleAsTestId}-margin`}>
          <td className="entry" data-testid={`${titleAsTestId}-margin-title`}>
            Margin:
          </td>
          <td className="value" data-testid={`${titleAsTestId}-margin-total`}>
            Calculating...
          </td>
        </tr>
      );
    }

    if (isNumberNegative(toNumber(margin))) {
      return (
        <tr>
          <td className="entry" data-testid={`${titleAsTestId}-margin-title`}>
            Margin:
          </td>
          <td
            className="value negative"
            data-testid={`${titleAsTestId}-margin-total`}
          >
            <RedMarginTrendDownwardArrow /> {displayText(margin)}
          </td>
        </tr>
      );
    }

    if (isNumberPositive(toNumber(margin))) {
      return (
        <tr>
          <td className="entry" data-testid={`${titleAsTestId}-margin-title`}>
            Margin:
          </td>
          <td
            className="value positive"
            data-testid={`${titleAsTestId}-margin-total`}
          >
            <GreenMarginTrendUpwardArrow /> {displayText(margin)}
          </td>
        </tr>
      );
    }

    return (
      <tr>
        <td className="entry" data-testid={`${titleAsTestId}-margin-title`}>
          Margin:
        </td>
        <td className="value" data-testid={`${titleAsTestId}-margin-total`}>
          {" "}
          {displayText(margin)}
        </td>
      </tr>
    );
  };

  return (
    <>
      <Container>
        <table>
          <thead>
            <tr>
              <th></th>
            </tr>
            <tr>
              <th data-testid={`${titleAsTestId}`} className="title">
                {title}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                data-testid={`${titleAsTestId}-total-cost-title`}
                className="entry"
              >
                Total Cost:
              </td>
              <td
                data-testid={`${titleAsTestId}-total-cost-value`}
                className="value"
              >
                {calculatingTotals || !cost
                  ? "Calculating..."
                  : displayText(cost)}
              </td>
            </tr>
            <tr>
              <td
                data-testid={`${titleAsTestId}-total-charge-title`}
                className="entry"
              >
                Total Charge:
              </td>
              <td
                data-testid={`${titleAsTestId}-total-charge-value`}
                className="value"
              >
                {calculatingTotals || !charge
                  ? "Calculating..."
                  : displayText(charge)}
              </td>
            </tr>
            {renderMargin()}
          </tbody>
        </table>
      </Container>
    </>
  );
};

const BudgetCreationHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .lead-instructions {
    font-size: var(--font-size-body);
    color: var(--component-secondary-text);
    margin-bottom: var(--size-4);
  }

  .current-step {
    display: flex;
    gap: var(--size-6);
    align-items: center;
    margin-bottom: var(--size-5);

    .title {
      font-size: var(--font-size-h-5);
      font-weight: var(--font-weight-medium);
      color: var(--component-primary-text);
    }

    .indication {
      font-size: var(--font-size-body);
      font-weight: var(--font-weight-medium);
      color: var(--component-tertiary-text);
    }
  }

  .current-progress {
    margin-bottom: var(--size-6);
    width: 200px;

    .ant-progress-text {
      font-size: var(--font-size-footnote);
      color: var(--component-tertiary-text);
    }
  }
`;

interface StepHeaderProps {
  step: number;
  totalSteps: number;
  title: string;
  subtitle: string;
  statistics: boolean;
}

export const StepHeader = (props: StepHeaderProps) => {
  const params = useParams();
  const { budget } = useBudget({
    budgetId: params.budgetId,
    budgetConfigVersionId: params.budgetConfigVersionId,
    track: "all",
  });
  return (
    <BudgetMatrixProvider
      options={{
        budgetId: params.budgetId,
        budgetConfigVersionId: params.budgetConfigVersionId,
        siteTrialId: budget?.trial.stId,
        track: "all",
      }}
    >
      <StepHeaderInner {...props}></StepHeaderInner>
    </BudgetMatrixProvider>
  );
};

export const StepHeaderInner = ({
  title,
  subtitle,
  step,
  totalSteps,
  statistics,
}: StepHeaderProps) => {
  const [matrix] = useBudgetMatrix();

  const visitCharges =
    matrix.budgetAggregate && budgetMatrix.visitCharges(matrix.budgetAggregate);
  const additionalCharges =
    matrix.budgetAggregate &&
    budgetMatrix.additionalCharges(matrix.budgetAggregate);
  const summary =
    visitCharges &&
    additionalCharges &&
    budgetMatrix.summary(visitCharges, additionalCharges);

  return (
    <BudgetCreationHeaderContainer>
      <div>
        <div className="current-step">
          <span className="title">{title}</span>
          {step !== 1 && <SaveIndication />}
        </div>
        <p className="lead-instructions">{subtitle}</p>
        <div className="current-progress">
          <Progress
            status="active"
            format={() => `Step ${step} of ${totalSteps}`}
            strokeColor={{
              from: "var(--shared-info)",
              to: "var(--application-site-brand-primary)",
            }}
            percent={(step / totalSteps) * 100}
          />
        </div>
      </div>
      {statistics && (
        <Space size={16}>
          <BudgetVersionCreationTotal
            title="Visit Charges"
            calculatingTotals={matrix.calculating}
            cost={visitCharges?.totalCost ?? money(0)}
            charge={visitCharges?.totalCharge ?? money(0)}
            margin={visitCharges?.margin ?? money(0)}
          />
          {step === 3 && (
            <BudgetVersionCreationTotal
              title="Additional Charges"
              calculatingTotals={matrix.calculating}
              cost={additionalCharges?.totalCost ?? money(0)}
              charge={additionalCharges?.totalCharge ?? money(0)}
              margin={additionalCharges?.margin ?? money(0)}
            />
          )}
          <BudgetVersionCreationTotal
            title="Line-Item Totals"
            calculatingTotals={matrix.calculating}
            cost={summary?.totalCost ?? money(0)}
            charge={summary?.totalCharge ?? money(0)}
            margin={summary?.margin ?? money(0)}
          />
        </Space>
      )}
    </BudgetCreationHeaderContainer>
  );
};
